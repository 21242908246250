import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Winner98_Topbar } from './winner98_Topbar';
import { winner98_initStrategies } from './winner98_initStrategies';
import { Winner98_SidebarPane1 } from './winner98_SidebarPane1';
import { winner98_initStyling } from './winner98_initStyling';
import { Winner98_Charting } from './component/winner98_Charting';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { ComponentBoundary } from '~/modules/SDK/FrError/components/ComponentBoundary';
export const winner98_init = (templateProps) => {
    winner98_initStyling(templateProps);
    winner98_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/winner98/loginView.jpg'] }]}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: '1D',
        custom_css_url: '../../winner/winner98/chartingTheme.css',
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.Charting = Winner98_Charting;
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 1fr 336px;
    user-select: none;
    grid-template-areas:
      'Row1 Row1'
      'Chart Drawer1'
      'Chart Drawer1';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr 336px;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = (<ComponentBoundary filepath='pages/winner98/_private/winner98_Topbar.tsx'>
      <Winner98_Topbar />
    </ComponentBoundary>);
    templateProps.layout.Drawer1 = (<ComponentBoundary filepath='pages/winner98/_private/winner98_SidebarPane1.tsx'>
      <Winner98_SidebarPane1 />
    </ComponentBoundary>);
    templateProps.hooks.add(useSignalrStart2_0);
};
